import { FC, memo } from 'react';
import { CloseRound } from 'components/ui/icons/Common';
import { useTranslation } from 'react-i18next';
import { useTranslationEditorContext } from 'providers/TranslationEditor';
import { useIsomorphicLayoutEffect } from 'hooks';
import { Dialog, CloseIconContainer, CloseIconText, Positioner } from './styled';

export type PopupProps = {
  open: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  style?: any;
  withScroll?: boolean;
  className?: string;
  positionerClassName?: string;
};

const Popup: FC<PopupProps> = ({ open, onClose, children, style, withScroll, className, positionerClassName }) => {
  const { t } = useTranslation();
  const { getDataAttributes, refresh } = useTranslationEditorContext();

  useIsomorphicLayoutEffect(() => {
    refresh();
  }, [open, refresh]);

  return open ? (
    <>
      <style>{`body {overflow:hidden !important}`}</style>
      <Positioner className={positionerClassName}>
        <Dialog withScroll={withScroll} style={style} data-testid="login-popup" className={className}>
          {children}
        </Dialog>
        <CloseIconContainer onClick={onClose} type="button" data-testid="close-button">
          <CloseIconText {...getDataAttributes('qr.common.close')}>{t('qr.common.close', 'Close')}</CloseIconText>
          <CloseRound />
        </CloseIconContainer>
      </Positioner>
    </>
  ) : null;
};

export default memo(Popup);
